import React from 'react';
import './ProfessionPage.scss';
import professionpagetop from '../../images/BackgroundImages/main.jpg';
import Navigation from '../Navigation/Navigation';
import { Parallax } from 'react-parallax';

//const { Link } = require("react-router-dom");

function ProfessionPage() {
    const parallaxTextStyleLarge = {
        color: "white",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        fontSize: "5vw",
        };
        // const parallaxTextStyleSmall = {
        // color: "white",
        // position: "absolute",
        // top: "60%",
        // left: "50%",
        // transform: "translate(-50%,-50%)",
        // fontSize: "36px",
        // };

    return (
        <div className="prof-page-container">
            <Navigation showOnLoad={true} />
            <Parallax bgImage={professionpagetop} strength={500}>
            <div style={{height: '90vh'}} className='dimBG'>
                <div style={parallaxTextStyleLarge}>Profession</div>
                {/* <div style={parallaxTextStyleSmall}>Learn what Profession means to us</div> */}
            </div>
            </Parallax>
            <div className="prof-page-content">
                <div style={{fontSize: '2vw'}}className="prof-page-par">
                    At Theta Tau we aim to teach and practice the professional skills that aren't typically taught in academic environments but make all the difference in our members' careers. We want to equip our members to represent themselves, their communities, and Theta Tau to the best of their abilities, empowering them to achieve and prosper. 
                </div>
            </div>
        </div>
    );
}

export default ProfessionPage;
