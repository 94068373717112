import React from 'react';
import './AboutPillars.scss';
import professionimg from '../../images/BackgroundImages/professionpillar.JPEG';
import serviceimg from '../../images/BackgroundImages/servicepillar.JPEG';
import kinshipimg from '../../images/BackgroundImages/kinshippillar.JPEG';

const { Link } = require("react-router-dom");

function AboutPillars() {
    return (
      <div className="AboutPillarsContainer">
        <div className="PillarsTitle">
          Our Pillars
        </div>
        <div className="PillarsPar">
          Profession, Kinship and Service form the foundation of our collective journey as an organization.
        </div>
        <div className="AboutPillars">
          <Link to="/profession" className="AboutPillar">
              <img className="AboutPillarImg" src={professionimg} alt="professionimg"/>
              <div className="AboutPillarTextLg">
                Profession
              </div>
              <div className="AboutPillarTextSm" >
                We provide opportunities for members to learn and practice key professional skills to ensure that they reach their career goals.
              </div>
          </Link>
          <Link to="/brotherhood" className="AboutPillar">
              <img className="AboutPillarImg" src={kinshipimg} alt="Kinshipimg"/>
              <div className="AboutPillarTextLg">
                  Kinship
              </div>
              <div className="AboutPillarTextSm" >
                We strive to develop a tight knit community between members during and after college through events and bonding activities.
              </div>
          </Link>
          <Link to="/service" className="AboutPillar">
              <img className="AboutPillarImg" src={serviceimg} alt="Serviceimg"/>
              <div className="AboutPillarTextLg">
                  Service
              </div>
              <div className="AboutPillarTextSm" >
                Through acts of service, our brothers are given the opportunity to help their community, allowing us to help those who need it most. 
              </div>
          </Link>
        </div>
      </div>
    );
}

export default AboutPillars;
