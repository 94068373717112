import React, { useState } from 'react';
import './Navigation.scss';
import { Navbar, Nav } from 'react-bootstrap';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

function Navigation({ showPosY, showOnLoad }) {
  const [hideNavOnScroll, setHideNavOnScroll] = useState(showOnLoad);

  useScrollPosition(({ prevPos, currPos }) => {
    let isThere;
    if (showPosY) {
      isThere = currPos.y > prevPos.y && currPos.y < -(showPosY)
    }
    else {
      isThere = currPos.y > prevPos.y
    }
    if (isThere !== hideNavOnScroll) setHideNavOnScroll(isThere)
  }, [hideNavOnScroll])

  return (
    // <Navbar className={hideNavOnScroll ? "nav-f5f5f5 navbar" : "nav-f5f5f5 navbar hide" } expand="sm" variant="light" fixed="top">
    <Navbar className="nav-f5f5f5 navbar" expand="sm" variant="light" fixed="top">
      <Navbar.Brand href="/">
        Theta Tau | SLO
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" >
        <div className='nav'>
        <Nav className="ml-auto" style={{alignItems:"end"}}>
          <Nav.Link bsPrefix="nav-link-custom" href="/">Home</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/about">About Us</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/rush">Recruitment</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/Members">Members</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/Alumni">Alumni</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/profession">Profession</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/Kinship">Kinship</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/service">Service</Nav.Link>
        </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
    );
  }
  
  export default Navigation;
