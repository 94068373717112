import React from 'react';
import './AboutStatistics.scss';
import { Chart } from 'primereact/chart';
import { brothers } from '../../BrothersObjects';

const brothersData = {
    totalBrothers: brothers.length,
    femaleBrothers: brothers.filter(brother => brother.gender === 'f').length,
    maleBrothers: brothers.filter(brother => brother.gender === 'm').length,
    otherBrothers: brothers.filter(brother => brother.gender === 'o').length,
    fourthYearBrothers: brothers.filter(brother => brother.year === '2025').length,
    thirdYearBrothers: brothers.filter(brother => brother.year === '2026').length,
    secondYearBrothers: brothers.filter(brother => brother.year === '2027').length,
    firstYearBrothers: brothers.filter(brother => brother.year === '2028').length,
};
const Majordata = [
    {major: 'AERO', length: brothers.filter(brother => brother.major[1] === 'AERO').length},
    {major: 'ARCE', length: brothers.filter(brother => brother.major[1] === 'ARCE').length},
    {major: 'BMED', length: brothers.filter(brother => brother.major[1] === 'BMED').length},
    {major: 'CE', length: brothers.filter(brother => brother.major[1] === 'CE').length},
    {major: 'CPE', length: brothers.filter(brother => brother.major[1] === 'CPE').length},
    {major: 'CSC', length: brothers.filter(brother => brother.major[1] === 'CSC').length},
    {major: 'EE', length: brothers.filter(brother => brother.major[1] === 'EE').length},
    {major: 'ENVE', length: brothers.filter(brother => brother.major[1] === 'ENVE').length},
    {major: 'ENGR', length: brothers.filter(brother => brother.major[1] === 'ENGR').length},
    {major: 'IME', length: brothers.filter(brother => brother.major[1] === 'IME').length},
    {major: 'IE', length: brothers.filter(brother => brother.major[1] === 'IE').length},
    {major: 'MATE', length: brothers.filter(brother => brother.major[1] === 'MATE').length},
    {major: 'ME', length: brothers.filter(brother => brother.major[1] === 'ME').length},
    {major: 'SE', length: brothers.filter(brother => brother.major[1] === 'SE').length},
    {major: 'STAT', length: brothers.filter(brother => brother.major[1] === 'STAT').length},
    {major: 'MFG', length: brothers.filter(brother => brother.major[1] === 'MFG').length},
    {major: 'ENVM', length: brothers.filter(brother => brother.major[1] === 'ENVM').length}
];

const label = Majordata.filter(major => major.length > 0).map(major => major.major);
const majorlengths = Majordata.filter(length => length.length > 0).map(major => major.length);
console.log(label)

function AboutStatistics() {

    const yearChart = {
        // labels: ['1st: ' + Math.round(brothersData.firstYearBrothers/brothersData.totalBrothers*100) + '%',
        //         '2nd: ' + Math.round(brothersData.secondYearBrothers/brothersData.totalBrothers*100) + '%',
        //         '3rd: ' + Math.round(brothersData.thirdYearBrothers/brothersData.totalBrothers*100) + '%',
        //         '4th: ' + Math.round(brothersData.fourthYearBrothers/brothersData.totalBrothers*100) + '%'],
        labels: ['1st', '2nd', '3rd', '4th'],
        datasets: [
            {
                data: [brothersData.firstYearBrothers,
                        brothersData.secondYearBrothers, 
                        brothersData.thirdYearBrothers, 
                        brothersData.fourthYearBrothers],
                backgroundColor: [
                    "#D5B4A7",
                    "#BE8C7A",
                    "#964840",
                    "#751214"
                ],
                hoverBackgroundColor: [
                    "#D5B4A7",
                    "#BE8C7A",
                    "#964840",
                    "#751214"
                ]
            }]
    };

    const majorChart = {
        labels: label,
        datasets: [
            {   data: majorlengths,
                backgroundColor: [
                    '#e5bba9',
                    '#daab98',
                    '#cf9c88',
                    '#c48c79',
                    '#b87d6a',
                    '#ad6e5b',
                    '#a25f4d',
                    '#965040',
                    '#8b4133',
                    '#7f3127',
                    '#74211b',
                    '#680d10'
                ],
                hoverBackgroundColor: [
                    '#e5bba9',
                    '#daab98',
                    '#cf9c88',
                    '#c48c79',
                    '#b87d6a',
                    '#ad6e5b',
                    '#a25f4d',
                    '#965040',
                    '#8b4133',
                    '#7f3127',
                    '#74211b',
                    '#680d10'
                ]
            }]
    };

//     const majorsWithMembers = ['AERO', 'ARCE', 'BMED', 'CE', 'IE', 'CSC', 'EE', 'ENVE', 'ME', 'CPE', 'ENGR', 'IME', 'MATE', 'SE', 'STAT'];
//     const datamajor =  [{data:  [Majordata.aerospaceBrothers,
//                                 Majordata.architecturalBrothers, 
//                                 Majordata.biomedicalBrothers, 
//                                 Majordata.civilBrothers,
//                                 Majordata.industrialBrothers,
//                                 Majordata.compSciBrothers,
//                                 Majordata.electricalBrothers,
//                                 Majordata.environmentalBrothers,
//                                 Majordata.mechanicalBrothers,
//                                 Majordata.computerBrothers,
//                                 Majordata.generalBrothers,
//                                 Majordata.industrialManufacturingBrothers,
//                                 Majordata.materialsBrothers,
//                                 Majordata.softwareBrothers,
//                                 Majordata.statisticsBrothers],
//                         backgroundColor: [
//                             "#D5B4A7",
//                             "#C69989",
//                             "#BE8C7A",
//                             "#B67E6B",
//                             "#A05A4E",
//                             "#964840",
//                             "#8B3631",
//                             "#802423",
//                             "#751214"
//                         ],
//                         hoverBackgroundColor: [
//                             "#D5B4A7",
//                             "#C69989",
//                             "#BE8C7A",
//                             "#B67E6B",
//                             "#A05A4E",
//                             "#964840",
//                             "#8B3631",
//                             "#802423",
//                             "#751214"
//                         ]}
// ];
    
    // const majorChart = {
    //     labels: majorsWithMembers, //['AERO', 'ARCE' , 'BMED', 'CE', 'IE', 'CSC', 'EE', 'ENVE', 'ME', 'CPE', 'ENGR', 'IME', 'MATE', 'SE', 'STAT'],
    //     datasets: [{
    //             data:  [Majordata.aerospaceBrothers,
    //                     Majordata.architecturalBrothers, 
    //                     Majordata.biomedicalBrothers, 
    //                     Majordata.civilBrothers,
    //                     Majordata.industrialBrothers,
    //                     Majordata.compSciBrothers,
    //                     Majordata.electricalBrothers,
    //                     Majordata.environmentalBrothers,
    //                     Majordata.mechanicalBrothers,
    //                     Majordata.computerBrothers,
    //                     Majordata.generalBrothers,
    //                     Majordata.industrialManufacturingBrothers,
    //                     Majordata.materialsBrothers,
    //                     Majordata.softwareBrothers,
    //                     Majordata.statisticsBrothers],
    //             backgroundColor: [
    //                 "#D5B4A7",
    //                 "#C69989",
    //                 "#BE8C7A",
    //                 "#B67E6B",
    //                 "#A05A4E",
    //                 "#964840",
    //                 "#8B3631",
    //                 "#802423",
    //                 "#751214"
    //             ],
    //             hoverBackgroundColor: [
    //                 "#D5B4A7",
    //                 "#C69989",
    //                 "#BE8C7A",
    //                 "#B67E6B",
    //                 "#A05A4E",
    //                 "#964840",
    //                 "#8B3631",
    //                 "#802423",
    //                 "#751214"
    //             ]
    //         }
    //     ]
    // };

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontColor: '#495057'
                }
            }],
            yAxes: [{
                ticks: {
                    fontColor: '#495057'
                }
            }]
        }
    };

    return (
        <div className="Statistics">
            <div className="StatsTitle">Chapter Statistics</div>
            <div className="ChartTitle">Total Members: {brothersData.totalBrothers}</div>
            <div className="DonutsContainer">
                {/*<div className="Donut">
                    <div className="ChartTitle">Gender</div>
                    <Chart type="doughnut" data={genderChart} options={lightOptions.legend} />
                </div> */}
                <div className="Donut">
                    <div className="ChartTitle">Year</div>
                    <Chart type="doughnut" data={yearChart} options={lightOptions.legend} />
                </div>
                <div className="Donut">
                    <div className="ChartTitle">Major</div>
                    <Chart type="doughnut" data={majorChart} options={lightOptions.legend} />
                </div>
                {/* <div className="Donut">
                    <div className="ChartTitle">Major</div>
                    <div className="chart">
                        {datamajor.map((value, index) => (
                        <div key={index} className="slice">
                            <div className="label">{majorsWithMembers[index]}</div>
                            <div className="bar" style={{ '--value': value }}></div>
                        </div>
                        ))}
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default AboutStatistics;
