import React from 'react';
import './Profile.scss';
import Navigation from '../../components/Navigation/Navigation'
//import Button from '@mui/material/Button';

function Profile(props) {
    const brother = props.brother;

    return (
        <div>
            <Navigation showOnLoad={true} />
            <div className="Profile">
                <div className="left-col">
                    <img src={brother.headshot} alt={brother.name} />
                    <h1>{brother.name}</h1>
                    <h2>Class of {brother.year}</h2>
                </div>
                <div className="right-col">
                    <div className="class-title">{brother.class} Class</div>
                    <h3>Major</h3>
                    <p>{brother.major[0]}</p>
                    {brother.experience.length > 0 && <h3>Experience</h3>}
                    {brother.experience.map(experience => <p>{experience.year}, {experience.company}, {experience.position}</p>)}
                    <h3>Campus Involvement</h3>
                    <p>{brother.campusInvolvement}</p>
                    <h3>Interests/Hobbies</h3>
                    <p>{brother.interestHobbies}</p>
                    <h3>Fun Fact</h3>
                    <p>{brother.funFact}</p>
                    <div className="buttons">
                        <button className="profile-button-red" onClick={() => window.open(brother.linkedin)} target="_blank">LinkedIn</button>
                        <button className="profile-button-red" onClick={() => window.open(brother.resume)} target="_blank">Resume</button>
                        <button className="profile-button-white" onClick={() => {props.history.push("/Members")}}>Back</button>
                    </div> 
                </div>
            </div>
         </div>
    )
};

export default Profile
