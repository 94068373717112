import React, { useState, useEffect } from 'react';
import './NewHomePage.scss';
import Navigation from '../Navigation/Navigation';
import logo from '../../images/IconsLogos/ttcoatarms.png';
import f1recruitment from '../../images/BackgroundImages/F1TTRecruitment.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AboutPillars from '../AboutPillars/AboutPillars';

//const { Link } = require("react-router-dom");


const learnMoreButton = <button className="home-top-button" onClick={scrollToAboutPillars}>Learn More <ArrowForwardIcon style={{scale: '.5'}}/></button>

function scrollToAboutPillars() {
  const aboutPillarsSection = document.getElementById('AboutSection');
  const yOffset = -80; // Adjust this value as needed to fine-tune scroll position
  const y = aboutPillarsSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
}

function NewHome(){ 

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className="home-container">
      <Navigation showOnLoad={true} />
      <div className="home-top">
        <div className="home-top-title" style={{ transform: `translateX(-${scrollPosition / 3}px)` }}>
          THETA TAU
        </div>
        <div className="home-top-subtitle" style={{ transform: `translateX(-${scrollPosition / 3}px)` }}>
          The Foremost Professional Co-ed Engineering Fraternity at Cal Poly San Luis Obispo
        </div>
        <img className="home-top-img" src={logo} alt="THETA TAU COAT OF ARMS" style={{ transform: `translateX(${scrollPosition / 3}px)` }}/>
        
        
        {/*<img className="recruitment-fall-2024" src={f1recruitment} alt="F1 Fall Recruitment 2024" style={{ transform: `translateX(-${scrollPosition / 3}px)` }}/>*/}
        
        <div className="down" style={{ transform: `translateX(-${scrollPosition / 3}px)` }}>
        
        {learnMoreButton}
        
        </div>
      </div>
      <div id="AboutSection" className="AboutSection">
          <AboutPillars />
      </div>
    </div>
  );
}

export default NewHome;