import React from 'react';
import './BrotherhoodPage.scss';
import brotherhoodpagetop from '../../images/BackgroundImages/kinship.JPEG';
import Navigation from '../Navigation/Navigation';
import { Parallax } from 'react-parallax';

//const { Link } = require("react-router-dom");

function BrotherhoodPage() {
const parallaxTextStyleLarge = {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    fontSize: "5vw",
    };
    // const parallaxTextStyleSmall = {
    // color: "white",
    // position: "absolute",
    // top: "60%",
    // left: "50%",
    // transform: "translate(-50%,-50%)",
    // fontSize: "36px",
    // };

    return (
    <div className="bro-page-container">
        <Navigation showOnLoad={true} />
        <Parallax bgImage={brotherhoodpagetop} strength={1}>
        <div style={{height: '90vh'}}className='dimBG'>
            <div style={parallaxTextStyleLarge}>Kinship</div>
            {/* <div style={parallaxTextStyleSmall}>Learn what Brotherhood means to us</div> */}
        </div>
        </Parallax>
        <div className="bro-page-content">
            <div className="bro-page-par">
                At Theta Tau we believe that having a strong and supportive community is essential in order for us to become the best person, and engineer, that we can be. We take pride in our diverse group of members that will share lasting connections beyond college.
            </div>
        </div>
    </div>
    );
}

export default BrotherhoodPage;
