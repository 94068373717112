import React from 'react';
import './RushPage.scss';
import rushpagetop from '../../images/BackgroundImages/recruitment.JPEG';
import Navigation from '../Navigation/Navigation';
import { Parallax } from 'react-parallax';
import f1recruitment from '../../images/BackgroundImages/F1TTRecruitment.jpg';
//import rushposter from '../../images/BackgroundImages/rushposter.jpg';
//import RushScheduleCard from '../RushScheduleCard/RushScheduleCard';

// const rushSchedule = [
//     {
//         title: 'Info Night',
//         weekday: 'Monday',
//         date: 'Jan. 11',
//         time: '8:00 pm',
//         attire: 'Casual',
//         location: 'Zoom',
//         description: 'Come listen to a short presentation on what Theta Tau is all about, and ask us any questions you might have!'
//     },
//     {
//         title: 'Brotherhood Night',
//         weekday: 'Tuesday',
//         date: 'Jan. 12',
//         time: '8:00 pm',
//         attire: 'Casual',
//         location: 'Zoom',
//         description: 'Come chat with all of our brothers and learn more about them and why they decided to join Theta Tau.'
//     },
//     {
//         title: 'Professional Night',
//         weekday: 'Wednesday',
//         date: 'Jan. 13',
//         time: '8:00 pm',
//         attire: 'Business',
//         location: 'Zoom',
//         description: 'We’ll be hosting a panel of brothers from SLO and other chapters discussing internships and how to land them.'
//     },
//     {
//         title: 'Service Night',
//         weekday: 'Thursday',
//         date: 'Jan. 14',
//         time: '8:00 pm',
//         attire: 'Casual',
//         location: 'Zoom',
//         description: 'Help us out with some remote service by classifying periodic variable stars for the SuperWASP Variable Stars Project!'
//     },
//     {
//         title: 'Game Night',
//         weekday: 'Friday',
//         date: 'Jan. 15',
//         time: '8:00 pm',
//         attire: 'Casual',
//         location: 'Zoom',
//         description: 'Come hang out with us and play Spyfall and other games. This will be a great time to work together with (or against) our brothers and your potential pledge bros!'
//     },
//     {
//         title: 'Interviews',
//         weekday: 'Saturday',
//         date: 'Jan. 16',
//         time: 'TBD',
//         attire: 'Business',
//         location: 'TBD',
//         description: 'Interviews are invite only. We will reach out to you with the time and place.'
//     },
//     {
//         title: 'Bid Night',
//         weekday: 'Sunday',
//         date: 'Jan. 17',
//         time: 'TBD',
//         attire: 'Casual',
//         location: 'TBD',
//         description: 'Bid Night is invite only. We will reach out to you with the time and place.'
//     },
// ];

// const rushScheduleCards = rushSchedule.map( event => <RushScheduleCard 
//     title={event.title} 
//     weekday={event.weekday}
//     date={event.date}
//     attire={event.attire}
//     location={event.location}
//     time={event.time}
//     description={event.description}
// />)

function RushPage() {
const parallaxTextStyleLarge = {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    fontSize: "6vw",
    };
    const parallaxTextStyleSmall = {
    color: "white",
    position: "absolute",
    top: "60%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    fontSize: "2vw",
    };

    return (
    <div className="rush-container">
        <Navigation showOnLoad={true} />
        <Parallax bgImage={rushpagetop} strength={500}>
        <div style={{height: '75vh'}}>
            <div style={parallaxTextStyleLarge}>Recruitment</div>
            <div style={parallaxTextStyleSmall}>Winter 2025</div>
        </div>
        </Parallax>
        <div className="rush-title">
            Winter 2025 Recruitment
        </div>
        <div className="rush-poster-content">
            <div className="poster-caption">
                <div className="caption-text">
                Welcome to Theta Tau, the foremost professional coed fraternity in San Luis Obispo! Our recruitment process is designed to provide both prospective members and our current members the opportunity to get to know each other better. Throughout the week-long journey, attendees will engage in various events and activities where they can learn about our three pillars and about the organization we've built. Whether it's attending workshops, social gatherings, or professional networking sessions, prospective members are encouraged to attend as many days as they can. Our recruitment process is completely free, and at the end of the week, we extend bids to those individuals who exemplify the qualities we seek in Theta Tau members. It's then up to them to decide if they want to begin our exciting new member process. Come join us and discover if Theta Tau is the perfect fit for you!
                </div>
                <a className="FooterLink" href="https://forms.gle/rmuTR5nE5SSVDPhE7" target="_blank" rel="noreferrer">
                    <button className="caption-button">Sign Up</button>
                </a>
                {/* <a className="FooterLink" href="https://www.facebook.com/events/401881674376912/?acontext=%7B%22ref%22%3A%2252%22%2C%22action_history%22%3A%22[%7B%5C%22surface%5C%22%3A%5C%22share_link%5C%22%2C%5C%22mechanism%5C%22%3A%5C%22share_link%5C%22%2C%5C%22extra_data%5C%22%3A%7B%5C%22invite_link_id%5C%22%3A2861114487546091%7D%7D]%22%7D" target="_blank" rel="noreferrer">
                    <button className="caption-button">Facebook Group</button>
                </a> */}
            </div>
            {/*<img className="rushposter" src={rushposter} alt="Rush Poster"/>*/}
        </div>
        <div>
        <img className="recruitment-fall-2024" src={f1recruitment} alt="F1 Fall Recruitment 2024" ></img> 
        </div>
        
            {/*<div className="rush-schedule">
            <div className="schedule-title">
                Schedule
            </div>
            <div className="rush-cards">
                {rushScheduleCards}
            </div>
        </div>*/}
    </div>
    );
}

export default RushPage;
