import React from 'react';
import './TimelineItem.scss';

function TimelineItem({ date, title, description }) {
    return (
      <div className="timeline-item">
          <div className="timeline-item-content">
          <div className="timeline-item-date">
                  {date}
              </div>
              <div className="timeline-item-title">
                  {title}
              </div>
              <div className="timeline-item-description">
                  {description}
              </div>
              <span className="circle"></span>
          </div>
      </div>
    );
}

export default TimelineItem;
