import React from 'react';
import './ServicePage.scss';
import servicepagetop from '../../images/BackgroundImages/service.JPEG';
import Navigation from '../Navigation/Navigation';
import { Parallax } from 'react-parallax';

//const { Link } = require("react-router-dom");

function ServicePage() {
  const parallaxTextStyleLarge = {
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      fontSize: "5vw",
      };
    //   const parallaxTextStyleSmall = {
    //   color: "white",
    //   position: "absolute",
    //   top: "60%",
    //   left: "50%",
    //   transform: "translate(-50%,-50%)",
    //   fontSize: "36px",
    //   };

      return (
        <div className="serv-page-container">
            <Navigation showOnLoad={true} />
            <Parallax bgImage={servicepagetop} strength={0}>
            <div style={{height: '90vh'}}className='dimBG'>
                <div style={parallaxTextStyleLarge}>Service</div>
                {/* <div style={parallaxTextStyleSmall}>Learn what Service means to us</div> */}
            </div>
            </Parallax>
            <div className="serv-page-content">
                <div className="serv-page-par">
                    At Theta Tau, we believe our community plays a crucial role in our everyday lives. Therefore it is our responsibility to give back to the community that helps shape us. We strive to leave a long-lasting positive impact on the community through different ways.
                </div>
            </div>
        </div>
      );
}

export default ServicePage;
